import React from "react";
import Fade from "react-reveal/Fade";

import Layout from "../components/Layout";
import Header from "../components/Header";
import Footer from "../components/Footer";
import config from "../../config";
import SocialLinks from "../components/SocialLinks";
const IndexPage = () => (
  <Layout
    title="termos de utilização"
    description="Os nossos termos de utilização"
  >
    <Header frontPage={false} title="Termos de utilização" subtitle=" " />
    <div className="content mt-5 pt-5">
      <div className="container pt-5">
        <div className="row justify-content-md-center">
          <Fade>
            <div className="col-sm-12 text-color">
              <p>
                O conteúdo deste website, incluindo marcas, desenhos, logotipos,
                texto, imagens, materiais audio e de vídeo, é propriedade da
                 {config.siteTitle}, a menos que indicado de outro modo através de uma
                referência à sua fonte e está protegido nos termos gerais de
                direito e pela legislação nacional e internacional de proteção
                da Propriedade Intelectual.{" "}
              </p>
              <p>
                Não é permitido exibir, reproduzir, distribuir, modificar,
                transmitir ou usar os conteúdos de forma alguma, para nenhum
                propósito público ou comercial sem o prévio e expresso
                consentimento por escrito das Companhias.
              </p>
              <p>
                No entanto, o utilizador pode imprimir qualquer excerto de
                material contido nestes sites desde que o mesmo não seja
                alterado de maneira alguma, seja para uso pessoal e não -
                comercial, contemplando a indicação da fonte e dos direitos de
                autor, e desde que outra indicação de propriedade não seja
                removida.
              </p>
              <p>
                Os conteúdos das páginas deste sites estão protegidos por
                Direitos de Autor e Direitos Conexos, Direitos de Propriedade
                Industrial e pela Lei da Criminalidade Informática. Embora os
                dados pessoais que nos são fornecidos estejam ao abrigo do
                definido na Política de Privacidade aqui publicada, qualquer
                informação ou outro conteúdo que o utilizador transmita para
                este site, seja através de correio eletrónico, mensagem para
                grupo de discussão(comentários) envio de ficheiros ou de outro
                modo, será considerado como não confidencial e não reservado.Ao
                transmitir conteúdos para este site, o utilizador
                automaticamente concede à {config.siteTitle} todos os direitos e propriedades
                exclusivas sobre esses mesmos conteúdos, concordando na sua
                utilização livre e inequívoca.
              </p>
            </div>
          </Fade>
        </div>
      </div>
    </div>

    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
